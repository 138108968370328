<template>
  <div class="breadcrumb-block">
    <div
      v-for="(navigation, index) in navigations"
      :key="index"
      class="breadcrumb"
    >
      <div
        :class="[
          { 'breadcrumb-title-minor': index != navigations.length - 1 },
          { 'breadcrumb-title-major': index == navigations.length - 1 },
        ]"
        @click="
          () =>
            index != navigations.length - 1 && handleNavigation(navigation.link)
        "
      >
        {{ navigation.title }}
        <p class="breadcrumb-icon" v-if="index != navigations.length - 1">></p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompanyBasePath } from "@/helper/utils.js";
import urlJoin from "url-join";
export default {
  name: "custom-breadcrumb",
  props: {
    navigations: {
      default: () => [],
    },
  },
  components: {},
  computed: {},
  methods: {
    mounted() {
      // will add later
    },
    handleNavigation(link = "") {
      this.$router.push({
        path: urlJoin(`${getCompanyBasePath()}${link}`),
      });
    },
  },
};
</script>

<style lang="less">
.breadcrumb-block {
  display: flex;
  .breadcrumb {
    .breadcrumb-title-minor {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 17px;
      /* identical to box height, or 142% */

      color: #41434c;

      opacity: 0.6;
      cursor: pointer;
    }
    .breadcrumb-title-major {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 17px;
      /* identical to box height, or 142% */

      color: #41434c;

      //   opacity: 0.6;
    }
    .breadcrumb-icon {
      display: inline;
      margin: 0px 10px;
      font-size: 12px;
      color: #41434c;
      opacity: 0.6;
    }
  }
}
</style>