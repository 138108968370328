<template>
  <div class="page_body">
    <ext-bridge v-on:hintChanged="hintChanged"></ext-bridge>
    <custom-breadcrumb
      :navigations="navs"
      class="breadcrumbs"
    ></custom-breadcrumb>
    <div :class="['table-container']">
      <div class="table-container-top">
        <div class="selling-location-block">
          <p class="selling-location-txt">Edit Selling Locations</p>
          <p class="selling-location-desc">
            Enter {{ this.marketplaceName }} location code to map and enable to make inventory flow
            through this location
          </p>
        </div>
        <div :class="['pad-left-top', 'cancel-btn']">
          <nitrozen-button
            theme="secondary"
            v-strokeBtn
            @click="cancelStoreMapping"
            >Cancel
          </nitrozen-button>
        </div>
        <div
          :class="[
            'pad-left-top',
            'save-btn',
            {
              'hint-mkp-store-id-element':
                isHintActive && currentHintStage == 3,
            },
          ]"
        >
          <nitrozen-button
            id="save"
            :class="{
              disabled: locationData.toggle_activation === false || (storeGstStatus(locationData) !== 'verified')
            }"
            theme="secondary"
            v-flatBtn
            @click="saveStoreMapping"
            >Save
          </nitrozen-button>
        </div>
      </div>
      <div class="store-name">
        {{ locationData.name }}
      </div>
      <div class="store-address">
        {{
          `${locationData.address.city} , ${locationData.address.state} , ${locationData.address.country}`
        }}
      </div>
      <div
        class="preview-strip-error preview-strip-gst-error"
        v-if="locationData.toggle_activation === false"
      >
        <inline-svg
          :src="'warning'"
          class="feature-icon preview-error-icon"
        ></inline-svg>
        <p class="preview-error-txt">
          Cannot Update location. Please try again in some time. 
        </p>
      </div>
      <div
        class="preview-strip-error preview-strip-gst-error"
        v-if="storeGstStatus(locationData) !== 'verified'"
      >
        <inline-svg
          :src="'warning'"
          class="feature-icon preview-error-icon"
        ></inline-svg>
        <p class="preview-error-txt">
          GST verification is pending for this selling location.
        </p>
        <nitrozen-button
          v-flatBtn
          class="preview-error-btn"
          theme="secondary"
          @click="verifyLocation(locationData.store_id || locationData.uid)"
          > Get it verified now</nitrozen-button
        >
      </div>
      <template v-if="!isEmpty(locationData)">
        <div class="headers">
          <div
            v-for="(header, index) in headers"
            :key="index"
            :class="[
              'header',
              'regular-xsp',
              'cl-Mako',
              {
                'sync-col-width':
                  header.title === 'Status' || header.title === 'Details',
              },
              {
                'location-code': header.title === 'Location Code',
              },
            ]"
          >
            {{ header.title }}
            <nitrozen-tooltip
              class="header-tooltip"
              position="top"
              v-if="header.tooltip"
            >
              {{ header.tooltip }}
            </nitrozen-tooltip>
          </div>
        </div>
        <div class="row-container">
          <div :class="['row', { 'row-flex': isMappingError }]">
            <div
              v-for="(header, index) in headers"
              :key="index"
              :class="[
                'row-value',
                'cl-Mako',
                {
                  'sync-col-width':
                    header.title === 'Status' || header.title === 'Details',
                },
                {
                  'location-code': header.title === 'Location Code',
                },
                {
                  'input-mkp-store-id':
                    locationData.error != '' &&
                    (header.key == 'marketplace_store_id' || header.key == 'additional_fields'),
                },
                {
                  'row-value-flex': isHintActive,
                },
              ]"
            >
              <nitrozen-hint :steps="hintSteps" @hintInactive="setHintInactive" v-if="isHintActive" hintType="loc_hint_status"> </nitrozen-hint>
              <template v-if="header.type === 'text'">
                <div class="text-overflow regular-xs"
                :id="header.key"
                >
                  {{
                    header.obj
                      ? getValue(locationData, `${header.obj}.${header.key}`) ||
                        "-"
                      : getValue(locationData, header.key) || "-"
                  }}
                </div>
                <nitrozen-tooltip v-if="header.key === 'name'" class="tooltip">
                  <div class="edit-icon"
                  @click="goToStore(locationData.uid)"
                  :id="header.key"
                  >
                    <adm-inline-svg src="edit"></adm-inline-svg>
                  </div>
                  <div
                    class="regular-xxs"
                    v-if="locationData.address && locationData.address.address1"
                  >
                    Address: {{ locationData.address.address1 }}
                  </div>
                  <div class="regular-xxs">
                    City: {{ locationData.address.city }}
                  </div>
                  <div class="regular-xxs">
                    State: {{ locationData.address.state }}
                  </div>
                  <div class="regular-xxs">
                    Pincode: {{ locationData.address.pincode }}
                  </div>
                </nitrozen-tooltip>
              </template>
              <template v-if="header.type === 'input'">
                <div
                  :class="[
                    'handle',
                    {
                      'hint-mkp-store-id-element':
                        isHintActive &&
                        header.key == 'quantity_buffer' &&
                        currentHintStage == 1,
                    },
                  ]"
                  :id="header.key"
                >
                  <nitrozen-input
                    :class="[
                      'regular-xs',
                      {
                        disabled: storeGstStatus(locationData) !== 'verified',
                        'number-width': header.keyType === 'number',
                      },
                    ]"
                    v-if="header.obj"
                    v-model.trim="locationData[header.obj][header.key]"
                    :type="header.keyType || 'text'"
                  ></nitrozen-input>
                  <nitrozen-input
                    :class="[
                      'regular-xs',
                      {
                        disabled: storeGstStatus(locationData) !== 'verified',
                        'number-width': header.keyType === 'number',
                      },
                    ]"
                     v-else-if="header.identifier"
                    v-model="locationData[header.key][header.identifier]"
                    type='text'
                    ></nitrozen-input>
                    <nitrozen-input
                    :class="[
                      'regular-xs',
                      {
                        disabled: storeGstStatus(locationData) !== 'verified',
                      },
                    ]"
                    v-else
                    v-model.trim="locationData[header.key]"
                    :min="
                      header.keyType && header.keyType == 'number' ? 0 : null
                    "
                    @keydown.native="header.keyType && header.keyType == 'number' ? checkNumberInput : ()=>true"
                    :type="header.keyType || 'text'"
                  ></nitrozen-input>
                </div>
                <div class="store-error">
                  <nitrozen-error
                    v-if="
                      (header.key == 'additional_fields' && header.key == 'marketplace_store_id') && locationData.error
                    "
                    >{{ locationData.error }}
                  </nitrozen-error>
                </div>
              </template>
              <template v-if="header.type === 'switch'">
                <div
                  :class="[
                    storeGstStatus(locationData) !== 'verified'
                      ? 'cursor-disabled'
                      : '',
                    {
                      'hint-mkp-store-id-element':
                        isHintActive &&
                        header.key == 'enabled' &&
                        currentHintStage == 2,
                    },
                  ]"
                  :title="
                    storeGstStatus(locationData) != 'verified'
                      ? 'GST is not verified'
                      : ''
                  "
                >
                  <nitrozen-toggle
                    :class="{
                      disabled: storeGstStatus(locationData) !== 'verified',
                    }"
                    id="enabled"
                    v-model.trim="locationData[header.key]"
                  >
                  </nitrozen-toggle>
                </div>
              </template>
              <template v-if="header.type === 'badge'">
                <div
                  :class="
                    storeGstStatus(locationData) !== 'verified'
                      ? 'cursor-disabled'
                      : ''
                  "
                  :title="
                    storeGstStatus(locationData) != 'verified'
                      ? 'GST is not verified'
                      : ''
                  "
                >
                  <nitrozen-badge
                    :class="{
                      disabled: storeGstStatus(locationData) !== 'verified',
                    }"
                    :state="locationData[header.key] ? 'success' : 'error'"
                    >{{ locationData[header.key] ? "ACTIVE" : "INACTIVE" }}
                  </nitrozen-badge>
                </div>
              </template>
              <template v-if="header.type === 'switch'"> </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  NitrozenButton,
  flatBtn,
  strokeBtn,
  NitrozenToggleBtn,
  NitrozenTooltip,
  NitrozenInput,
  NitrozenHint,
  NitrozenError,
  NitrozenBadge,
} from "@gofynd/nitrozen-vue";

import AdminMarketplacesService from "@/services/main.service";
import admInlineSvg from "@/components/common/adm-inline-svg.vue";
import InlineSvg from "@/components/common/inline-svg";
import breadCrumb from "@/components/common/breadcrumbs.vue";
import pick from "lodash/pick";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import root from "window-or-global";
import extBridge from "./ext-bridge.vue";
import { getCompany } from "@/helper/utils.js";
import { MARKETPLACE_NAME } from "@/helper/constant.js";

const HEADERS = [
  {
    title: "Location Type",
    type: "text",
    key: "store_type",
  },
  {
    title: "Location Code",
    type: "text",
    key: "store_code",
  },
  {
    title: "Marketplaceplace StoreId",
    type: "input",
    key: "marketplace_store_id",
  },
  {
    title: "Enable",
    type: "switch",
    key: "enabled",
    tooltip:
      "Selling location Inventory sync can be enabled only if GST is verified",
  },
];
const EXTRA_HEADERS_FIELDS = {
  [MARKETPLACE_NAME]: {
    extraFields: [
      {
        key: "marketplace_store_id",
        type: "input",
        title: `${MARKETPLACE_NAME} Location Code`,
      },
      {
        type: "input",
        key: "additional_fields",
        identifier: "vendor_code",
        title: `${MARKETPLACE_NAME} Vendor Code`,
      },
      {
        title: "Quantity Buffer",
        type: "input",
        key: "quantity_buffer",
        keyType: "number",
        tooltip:
          "Quantity will be sent only if it's greater than quantity buffer",
      },
    ],
    schema: {
      [`${MARKETPLACE_NAME.toLowerCase()} Location ID`]: { type: "string" },
      "Quantity buffer": { type: "string" },
    },
  },
};
const STORE_TYPE = {
  high_street: "High Street",
  mall: "Mall",
  warehouse: "Warehouse",
};
const PAGINATION = {
  limit: 10,
  current: 1,
  total: 0,
};

const FUNNEL_CARDS = [
  {
    title: "Total",
    help: "",
    model: "total",
    type: "box",
  },
  {
    icon: "bottom",
    percentage: "100",
    type: "stats",
  },
  {
    title: "Mapped",
    help: "",
    model: "mapped",
    type: "box",
  },
  {
    icon: "bottom",
    percentage: "100",
    type: "stats",
  },
  {
    title: "Active",
    help: "",
    model: "active",
    type: "box",
  },
];

const HINTS = [{}, {}, {}, {}];

const NAVS = [
  {
    title: "Home",
    link: "/",
  },
  { title: "Selling Locations", link: "/locations" },
  { title: "Edit" },
];

export default {
  name: "marketplace-stores",
  components: {
    "nitrozen-button": NitrozenButton,
    "nitrozen-toggle": NitrozenToggleBtn,
    "nitrozen-input": NitrozenInput,
    "nitrozen-tooltip": NitrozenTooltip,
    "nitrozen-badge": NitrozenBadge,
    "adm-inline-svg": admInlineSvg,
    "nitrozen-hint": NitrozenHint,
    "nitrozen-error": NitrozenError,
    "custom-breadcrumb": breadCrumb,
    "inline-svg": InlineSvg,
    "ext-bridge": extBridge,
  },
  directives: {
    flatBtn,
    strokeBtn,
  },
  props: {
    locationData: {},
  },
  data() {
    return {
      marketplaceName : MARKETPLACE_NAME,
      hints: [],
      navs: NAVS,
      allStores: [],
      tempStores: [],
      headers: [],
      funnelCards: [],
      filteredStores: [],
      companyId: "",
      storeType: STORE_TYPE,
      searchText: "",
      searchPlaceholderText: "",
      statusList: [
        {
          text: "Active",
          value: "true",
        },
        {
          text: "Inactive",
          value: "false",
        },
      ],
      hintSteps:[
        {
          title: `2. Enter the ${MARKETPLACE_NAME} Location code in the input field.`,
          element: "#marketplace_store_id",
          intro: `You can get Location Code from your ${MARKETPLACE_NAME} seller account.`,
          skipLabel: "X",
          hidePrev: true,
          position: "right",
        },
        {
          title: "3. Enter Quantity Buffer.",
          element: "#quantity_buffer",
          intro:
            `Enter a minimum quantity of products that should be available at the location. The inventory will become zero on ${MARKETPLACE_NAME} marketplace if it falls below this buffer quantity.`,
          position: "left",
        },
        {
          title: "4. Enable this location.",
          element: "#enabled",
          intro:
            "Turn ON the toggle button to mark this location available for selling products.",
          position: "left",
        },
        {
          title: "5. One last step!",
          nextToDone:true,
          element: "#save",
          intro: "Click this “Save” button to map your first selling location.",
        },
      ],
      selectedStoreStatus: "all",
      inProgress: false,
      storeInfo: {},
      showDialog: false,
      csvPreviewTitle: "",
      selectedStores: [],
      pagination: { ...PAGINATION },
      storeStats: {
        acount: 0,
        total: 0,
      },
      cleanState: {},
      inventorySync: true,
      enabledStore: [],
      storeToBeSynced: [],
      currentHintStage: 0,
      isHintActive: false,
      isMappingError: false,
    };
  },
  mounted() {
    console.log("location is", this.locationData);
    this.hints = cloneDeep(HINTS);
    let hintStatus= localStorage.getItem("loc_hint_status");
    if(!hintStatus){
      this.isHintActive=true;
    }
    this.pagination.current = 1;
    this.pagination.limit = 10;
    this.searchPlaceholderText = "Search by location name";
    this.inProgress = true;
    AdminMarketplacesService.fetchStoreMapping().then(({ data }) => {
      this.setStoreData(data);
      this.headers = this.modifyHeaders(cloneDeep(HEADERS));
      this.funnelCards = cloneDeep(FUNNEL_CARDS);
      this.inProgress = false;
    });
  },
  methods: {
     setHintInactive() {
      document.cookie = "introjs-dontShowAgain" + '=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      this.isHintActive = false;
    },
    handleHintMkpStoreId() {
      this.currentHintStage = this.currentHintStage + 1;
    },
    handleHintOnSave() {
      this.isHintActive = false;
    },
   hintChanged() {
      console.log("called hint element");
      this.isHintActive = !this.isHintActive;
      this.currentHintStage = 0;
      let isHintsAlreadyShown = localStorage.getItem("loc_hint_status");
      if (isHintsAlreadyShown == "shown") {
        localStorage.removeItem("loc_hint_status");
      } else if(!this.isHintActive){
        localStorage.setItem("loc_hint_status", "shown");
      }
         document.cookie = "introjs-dontShowAgain" + '=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      console.log("currentHint",this.currentHintStage, "ishintActive",this.isHintActive)
    },
    storeGstStatus(store) {

      let gstStatus = "unverified";
      if (store.documents) {
        store.documents.some((doc) => {
          if (doc.type === "gst" && doc.verified === true) {
            gstStatus = "verified";
            return true;
          }
        });
      } else if (store.stage) {
        gstStatus = store.stage;
      }
      return gstStatus;
    },
    checkNumberInput(e) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode == 8
        )
      ) {
        e.preventDefault();
      }
      return true;
    },
    isEmpty(obj) {
      return isEmpty(obj);
    },
    getValue(obj, key) {
      return get(obj, key);
    },
    setStoreData(data) {
      this.allStores = data.items;
      this.allStores.map((store) => {
        store.error = "";
      });
      this.pagination.total = this.allStores.length;
      this.transformStores(this.allStores);
      this.filteredStores = this.allStores;
      this.cleanState = this.allStores.map((store) => {
        return pick(store, [
          "store_id",
          "marketplace_store_id",
          "enabled",
          "quantity_buffer",
          "store_code",
        ]);
      });
    },
    transformStores(stores) {
      this.allStores = stores.map((store) => {
        if (typeof enabled === "string")
          store.enabled = store.enabled === "true" ? true : false;
        store.enabled = !!store.enabled;
        store.store_type = this.storeType[store.store_type] || store.store_type;
        store.meta = store.meta || {};
        return store;
      });
    },
    modifyHeaders(headers) {
      let mkpStoreIdIndex = headers.findIndex(
        (header) => header.key === "marketplace_store_id"
      );
      headers.splice(mkpStoreIdIndex, 1);
      headers.splice(
        mkpStoreIdIndex,
        0,
        ...EXTRA_HEADERS_FIELDS[this.marketplaceName].extraFields
      );

      return headers;
    },
    verifyLocation(storeId) {
      let hostnames = root.location.hostname.split(".");
      let fyndPlatformDomain = `${hostnames[2]}.${hostnames[3]}`;
      window.open(
        `https://platform.${fyndPlatformDomain}/company/${getCompany()}/profile/edit-store/${storeId}`,
        "_blank"
      );
    },
    saveStoreMapping() {
      console.log("location_data", [this.locationData]);
      if (this.validateForm()) {
        this.inProgress = true;
        let storeMapping = [this.locationData]
          .map((store) => {
            console.log("store", store);
            store.marketplace_store_id =
              store.marketplace_store_id && store.marketplace_store_id.trim();
            store.additional_fields.vendor_code =
            store?.additional_fields?.vendor_code ? store?.additional_fields?.vendor_code?.trim() : '';
            return store;
          })
          .filter((store) => {
            return store.stage === "verified" && store.marketplace_store_id;
          })
          .map((store) => {
            store.store_id = store.uid;
            return pick(store, [
              "store_id",
              "marketplace_store_id",
              "enabled",
              "quantity_buffer",
              "store_code",
              "additional_fields",
            ]);
          });

        return AdminMarketplacesService.updateStoreMapping(storeMapping)
          .then((res) => {
            this.inProgress = false;
            this.cleanState = this.allStores.map((store) => {
              return pick(store, [
                "store_id",
                "marketplace_store_id",
                "enabled",
                "quantity_buffer",
                "store_code",
                "additional_fields",
              ]);
            });
            this.$snackbar.global.showSuccess(`Mapping saved successfully.`);
            this.$router.push({
              path: `locations?hint=1`,
            });
            return res;
          })
          .catch(() => {
            this.$snackbar.global.showError(`Dupliacte Location Code`);
          });
      }
    },
    cancelStoreMapping() {
      this.$router.push({
        path: `locations?hint=1`,
      });
    },
    validateForm() {
      let formValid = true;
      [this.locationData].forEach((store) => {
        if (store.enabled && (!store.marketplace_store_id || !store.additional_fields.vendor_code)) {
          store.error = `Please provide ${MARKETPLACE_NAME} location code and ${MARKETPLACE_NAME} vendor code.`;
          formValid = false;
        } else if (store.error) {
          delete store.error;
        }
      });
      if (!formValid) {
        this.$snackbar.global.showError("Please enter valid store data.");
      }
      this.$forceUpdate();
      if (!formValid) {
        this.isMappingError = true;
      }
      return formValid;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import "../less/theme.less";
@import "../less/media.less";
@import "../less/text.less";
@import "../less/color.less";
@import "../less/page-header.less";
@import "../less/variables.less";
.breadcrumbs {
  margin: 24px;
  padding-left: 24px;
}
.funnel-container {
  margin: 24px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  border: 0.5px solid gray;

  .funnel-top-span {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .funnel-head {
      width: 80%;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #41434c;
    }
    .funnel-sm-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #666666;
    }
    .funnel-sm-bold-text {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      color: #41434c;
    }
  }

  .funnel-cards {
    display: flex;
    align-items: baseline;

    .funnel-card {
      padding: 12px;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 4px;
      width: 18%;
      margin: 0px 24px;

      .funnel-card-title {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #666666;
      }
      .funnel-card-model {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        color: #41434c;
      }
    }
    .funnel-card-active {
      background-color: #e7eeff;
    }
    .funnel-stats {
      .funnel-stat-percentage {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #828282;
      }
    }
  }
}
.fp-stats {
  margin-top: -50px;
  margin-left: auto;
}
.mkps {
  display: inline-flex;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.number-width {
  width: 100%;
}
.cursor-disabled {
  cursor: not-allowed;
}
.hint-mkp-store-id-block {
  display: flex;
  justify-content: space-around;
  :deep(.nitrozen-tooltip-left) {
    top: -80px;
  }
}
.hint-mkp-store-id-element {
  margin: 25px 10px;
  background-color: white;
  z-index: 1000;
  padding: 8px;
  border-radius: 4px;
}

.pagination {
  margin-top: 20px;
}
.header-tooltip {
  margin: 2px 0px 2px 2px;
  :deep(.nitrozen-tooltiptext) {
    left: -50px;
    padding: 10px;
    line-height: 1.6;
    margin-bottom: 5px;
  }
  :deep(.nitrozen-tooltip-top:after) {
    left: 60%;
  }
}
.manual-inv-diag {
  :deep(.nitrozen-dialog-body) {
    overflow: unset !important;
  }
}
.manual-inv-label {
  margin-top: 30px;
}
.trigger-btn {
  margin-right: 20px;
}
.sync-button {
  display: block;
  margin: 24px 0px 20px;
}
.brands-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .selected-brands {
    border: 2px solid RoyalBlue !important;
  }
  .brands-div {
    margin: 0 24px 24px 0;
    border: 2px solid White;
    cursor: pointer;
    padding: 6px;
    height: 100px;
    width: 90px;
    box-sizing: border-box;
    .brand-img-div {
      background-color: Alabaster2;
      height: 60px;
      width: 70px;
      margin: auto;
      .brand-img {
        width: 100%;
        height: 100%;
      }
    }
    .brand-name {
      text-align: center;
      margin: 10px 0px;
      line-height: 1.2;
      font-size: 11px;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
.page-error {
  padding: 1%;
}
.no-content {
  :deep(img) {
    height: 250px;
    width: 300px;
  }
}
.csv-preview-diag {
  :deep(.nitrozen-dialog-body) {
    overflow: auto;
    max-height: 600px;
  }
}
.tooltip {
  line-height: 1.6;
  margin-left: 5px;
  :deep(.nitrozen-tooltiptext) {
    padding: 15px;
    text-align: left;
    min-width: 210px;
  }
  :deep(.nitrozen-tooltip-bottom) {
    margin-left: -70px;
  }
}
.preview-strip-gst-error {
  border: 1px solid rgba(235, 87, 87, 0.1) !important;
}
.preview-strip-error {
  background: #ffe7e7;
  border: 1px solid #eb5757;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  margin: 12px 0px;
  .preview-error-ico {
    margin-right: 6px;
  }
  .preview-error-txt {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: #41434c;
    margin-right: auto;
    margin-left: 6px;
  }
  .preview-error-btn {
    background: #e8505b;
    border-radius: 4px;
  }
}
.edit-icon {
  cursor: pointer;
  float: right;
  :deep(svg) {
    height: 24px;
    width: 24px;
  }
}
.pad-left-top {
  margin-left: 20px;
  margin-top: 20px;
}
.store-stats {
  margin-top: 33px;
  margin-left: 5px;
  max-width: 80px;
  margin-right: 5px;
  min-width: 60px;
}
.store-status-sep {
  margin-top: 30px;
  font-size: 16px;
}

.search {
  margin-right: 15px;
  width: 350px;
}
.input-box {
  display: flex;
  justify-content: space-between;
  margin: 24px 0px;
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 12px;
  :deep(.nitrozen-dropdown-container) {
    flex-direction: row;
  }
  .status-filter {
    :deep(.nitrozen-dropdown-label) {
      margin: 12px;
    }
    :deep(.nitrozen-select-wrapper) {
      min-width: 200px;
    }
    :deep(.nitrozen-select__trigger) {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 23px;
      color: #41434c;
      opacity: 0.8;
    }
  }
  .btns-filters {
    display: flex;
    flex-wrap: wrap;
    input[type="file"] {
      display: none;
    }
  }
}
.table-container {
  padding: 24px;
  margin: 24px;
  background-color: White;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 12px;
  height: 85vh;
  .selling-location-block {
    width: 92%;
    margin-bottom: 12px;
    .selling-location-txt {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin: 6px 0px;
      color: #41434c;
    }
    .selling-location-desc {
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      margin: 6px 0px;
      color: #666666;
    }
  }
  .table-container-top {
    width: 100%;
    display: flex;
    .cancel-btn {
      margin-right: 24px;
    }
    .save-btn {
      margin-left: auto;
    }
    .hint-save-btn-block {
      top: -40px;
      position: relative;
    }
  }
  .store-name {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;

    color: #5a5a5a;
    margin: 6px 0px;
  }
  .store-address {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;

    color: #5a5a5a;
    margin: 6px 0px 12px;
  }
}
.headers {
  display: flex;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  padding: 0px 12px;
  border-radius: 4px 4px 0px 0px;
  .header {
    flex: 0.2;
    padding: 15px 10px;
    text-overflow: ellipsis;
    margin-right: 10px;
  }
  .sync-col-width {
    flex: 0.1;
    display: flex;
  }
  .location-code {
    min-width: 145px;
  }
}
.row-container {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  border-top: none;
  .row {
    display: flex;
    margin: 0px 12px;
    border-bottom: 1px solid #e0e0e0;
    .row-value {
      display: flex;
      align-items: center;
      flex: 0.2;
      padding: 15px 10px;
      margin-right: 10px;
      .text-overflow {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
        color: #666666;
      }
    }
    .row-value-flex {
      display: flex;
      flex: 0.2;
      padding: 15px 10px;
      margin-right: 10px;
      flex-direction: column;
    }
    .sync-col-width {
      flex: 0.1;
    }
    .location-code {
      min-width: 145px;
    }
    .input-error {
      display: flex;
      flex-direction: column;
      position: relative;
      top: 10px;
      padding-bottom: 0;
    }
    .handle {
      width: 130px !important;
    }
    .input-mkp-store-id {
      display: unset !important;
    }
    .store-error {
      max-width: 135px;
      margin-bottom: 12px;
    }
  }
  .row-flex {
    display: flex;
    align-items: baseline;
    margin: 0px 12px;
    border-bottom: 1px solid #e0e0e0;
  }
}

.regular-xsp {
  font-weight: 400;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  :deep(.n-input) {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;

    color: #666666;
  }
}
.darker-sm {
  font-weight: 600;
  font-size: 16px;
}
.main {
  position: relative;
  top: 10px;
}
#op {
  position: relative;
  top: 10px;
}
</style>
